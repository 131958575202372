import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const HeroSection: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  // タイピングアニメーション用のステート
  const [typedText, setTypedText] = useState('');
  const fullText = "Creating New Value and Shaping the Future through IT.";
  const [isBlinking, setIsBlinking] = useState(false); // カーソルのブリンクアニメーション用

  useEffect(() => {
    // 画面サイズが768px以下ならスマホと判断
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // 初回レンダリング時にサイズをチェック
    handleResize();

    // ウィンドウのリサイズイベントでチェック
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const scene = new THREE.Scene();

      // 背景のグラデーションを設定
      const gradientTexture = new THREE.CanvasTexture(generateGradient());
      scene.background = gradientTexture;

      // カメラの設定
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, 0, 15);

      const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);

      // 照明設定
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(10, 10, 10);
      scene.add(directionalLight);

      // GLTFLoaderを使ってモデルを読み込む
      const loader = new GLTFLoader();
      let planet: THREE.Object3D;
      loader.load('/models/stylized_planet.glb', (gltf) => {
        planet = gltf.scene;
        planet.position.set(isMobile ? 3 : 8, isMobile ? -5 : -2, 0); // スマホとPCで位置を調整
        planet.scale.set(isMobile ? 6 : 7, isMobile ? 6 : 7, isMobile ? 6 : 7); // スケールを変更
        scene.add(planet);
      });

      // OrbitControls 設定（スマホ版では無効）
      let controls: OrbitControls | undefined;
      if (!isMobile) {
        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.enableZoom = false;
        controls.enablePan = false;
        controls.rotateSpeed = 0.5;
      }

      // アニメーション設定
      let autoRotate = true;

      const animate = () => {
        requestAnimationFrame(animate);

        if (planet && autoRotate) {
          planet.rotation.y += 0.005; // 自転アニメーション
        }

        if (controls) controls.update(); // デスクトップでのみ操作可能に

        renderer.render(scene, camera);
      };

      animate();

      if (controls) {
        controls.addEventListener('start', () => (autoRotate = false));
        controls.addEventListener('end', () => (autoRotate = true));
      }

      // ウィンドウリサイズ時に対応
      window.addEventListener('resize', () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        renderer.setSize(width, height);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      });
    }
  }, [isMobile]); // isMobileが変わったら再実行

  // タイピングアニメーションの実装
  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < fullText.length) {
        setTypedText(fullText.substring(0, index + 1)); // 全体から必要な部分のみ表示
        index++;
      } else {
        clearInterval(interval);
        setIsBlinking(true); // 全ての文字が表示されたらカーソルを点滅させる
      }
    }, 20); // 文字の表示速度を調整（少し遅くして安定化）
  
    return () => clearInterval(interval); // コンポーネントがアンマウントされたらクリーンアップ
  }, []);

  const generateGradient = (): HTMLCanvasElement => {
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const context = canvas.getContext('2d');

    const gradient = context!.createLinearGradient(0, 0, 512, 512);
    gradient.addColorStop(0, '#f0f2f5');
    gradient.addColorStop(0.5, '#9ea7c6');
    gradient.addColorStop(1, '#9ea7c6');

    context!.fillStyle = gradient;
    context!.fillRect(0, 0, canvas.width, canvas.height);

    return canvas;
  };

  return (
    <div className="relative w-full h-screen z-10">
      <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />
      
      {/* スマホとPCで異なるクラスを適用 */}
      <div className={`absolute left-0 w-full h-full z-20 px-8 ${
        isMobile ? 'top-[200px]' : 'justify-center flex flex-col items-start'
      }`}>
        {/* テキスト部分はクリックを無効にするために pointer-events-none */}
        <div className="pointer-events-none">
          <h1 className="inline-block text-2xl md:text-4xl font-bold text-white bg-black mb-4 ps-2 pe-4">
            摩擦から革新を生み出す
          </h1>
          <p className="text-lg md:text-2xl text-gray-700 mb-4 ps-2 pe-4">
            {typedText}
            {isBlinking && <span className="inline-block animate-blink">|</span>}
          </p>
        </div>
      </div>

      {/* Tailwind CSSでのカスタムアニメーション */}
      <style>{`
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0; }
        }
        .animate-blink {
          animation: blink 1s steps(1) infinite;
        }
      `}</style>
    </div>
  );
};

export default HeroSection;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import { FaArrowRight } from 'react-icons/fa';
import PageBasicTemplate from '../components/PageBasicTemplate';
import FadeInSection from '../components/FadeInSection';
import { apiService } from '../utils/ApiService';
import DateFormatter from '../utils/DateFormatter';

interface NewsItem {
  id: number;
  title: string;
  published_date: string;
  updated_date: string;
  category: string;
  content: string;
}

const News: React.FC = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号
  const [newsPerPage] = useState(6); // 1ページあたりのニュース数

  useEffect(() => {
    apiService.get<NewsItem[]>('/news-items')
      .then((data: NewsItem[]) => setNewsItems(data))
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);


  // ページネーションに基づいて表示するニュースを取得
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNewsItems = newsItems.slice(indexOfFirstNews, indexOfLastNews);

  // 総ページ数を計算
  const totalPages = Math.ceil(newsItems.length / newsPerPage);

  // Contentの最初の100文字のみを表示する関数
  const getPreviewText = (content: string, maxLength: number = 100) => {
    const plainText = content.replace(/<\/?[^>]+(>|$)/g, ''); // HTMLタグを削除
    return plainText.length > maxLength
      ? plainText.substring(0, maxLength) + '...'
      : plainText;
  };

  // ページを変更する関数
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <PageBasicTemplate title="お知らせ一覧" sub_title="最新のお知らせを一覧表示">
      <FadeInSection>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {currentNewsItems.map((item) => (
            <Link
              to={`/news/${item.id}`} // クリック時に詳細ページに遷移
              key={item.id}
              className="bg-white rounded-lg shadow-md m-4 cursor-pointer transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-xl"
            >
              <div className="p-6">
                <h2 className="text-xl font-bold text-gray-600 mb-1">{item.title}</h2>
                <p className="text-gray-500 mb-1">{item.category} - {DateFormatter.formatToJST(item.published_date, 'YYYY年MM月DD日')}</p>
                
                <p className="text-gray-500 mb-4" dangerouslySetInnerHTML={{ __html: getPreviewText(item.content) }} />
                <div className="inline-block text-blue-500 font-semibold hover:underline flex items-center">
                  <span>もっと見る</span>
                  <FaArrowRight className="ml-2" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </FadeInSection>

      {/* ページネーション */}
      <div className="flex justify-center mt-8 space-x-2">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-md bg-blue-500 text-white ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        >
          前のページ
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`px-4 py-2 rounded-md ${currentPage === index + 1 ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-md bg-blue-500 text-white ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        >
          次のページ
        </button>
      </div>
    </PageBasicTemplate>
  );
};

export default News;
